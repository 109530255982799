import { Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthenticationGuard } from './core/authentication/authentication.guard';
import { JWTGuard } from './jwt.guard';
import { UserRoles } from './shared/models/user.model';

export const appRoutes: Routes = [
  { path: '', redirectTo: '/Login', pathMatch: 'full' },
  {
    path: '',
    loadComponent: () => import('../app/core/layout/navigation/navigation.component').then(m => m.NavigationComponent),
    children: [
      {
        path: 'Login',
        children: [
          {
            path: '',
            loadComponent: () => import('../app/modules/login/login.component').then(m => m.LoginComponent)
          },
          {
            path: ':error',
            loadComponent: () => import('../app/modules/login/login.component').then(m => m.LoginComponent)
          }
        ]
      },
      {
        path: 'Reset-Password',
        children: [
          {
            path: ':email/:code',
            loadComponent: () => import('../app/modules/reset-password/reset-password.component').then(m => m.ResetPasswordComponent)
          },
          {
            path: '**',
            redirectTo: '/Login'
          }
        ]
      },
      {
        path: 'Forgot-Password',
        loadComponent: () => import('../app/modules/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent)
      }
    ]
  },
  {
    path: 'Portal',
    loadComponent: () => import('../app/core/layout/side-navigation/side-navigation.component').then(m => m.SideNavigationComponent),
    canActivate: [AuthenticationGuard],
    canActivateChild: [JWTGuard],
    children: [
      {
        path: 'Loading',
        loadComponent: () => import('../app/shared/components/empty/empty.component').then(m => m.EmptyComponent)
      },
      {
        path: 'Overview',
        loadComponent: () => import('../app/modules/overview2/overview2.component').then(m => m.Overview2Component)
      },
      {
        path: 'ScheduleShifts',
        children: [
          {
            path: 'New/:templateMode',
            loadComponent: () => import('../app/modules/schedule-shifts/new-shift/new-shift.component').then(m => m.NewShiftComponent)
          },
          {
            path: 'Edit/:shiftId',
            loadComponent: () => import('../app/modules/schedule-shifts/edit-shift/edit-shift.component').then(m => m.EditShiftComponent)
          },
          {
            path: '**',
            redirectTo: 'New/Custom'
          }
        ]
      },
      {
        path: 'Facilities',
        children: [
          {
            path: '',
            loadComponent: () => import('../app/modules/locations/locations.component').then(m => m.LocationsComponent)
          },
          {
            path: 'Add',
            loadComponent: () => import('../app/modules/locations/add-location/add-location.component').then(m => m.AddLocationComponent)
          },
          {
            path: 'Edit/:locationId',
            loadComponent: () => import('../app/modules/locations/edit-location/edit-location.component').then(m => m.EditLocationComponent)
          }
        ]
      },
      {
        path: 'Network',
        loadComponent: () => import('../app/modules/facility/facility.component').then(m => m.FacilityComponent)
      },
      {
        path: 'Clinicians',
        children: [
          {
            path: '',
            loadComponent: () => import('../app/modules/clinicians/clinicians.component').then(m => m.CliniciansComponent)
          },
          {
            path: 'Credentials',
            loadComponent: () => import('../app/modules/clinicians/credentials/credentials.component').then(m => m.CredentialsComponent)
          },
          {
            path: 'view/:userId',
            loadComponent: () => import('../app/modules/clinicians/view-clinicians/view-clinicians.component').then(m => m.ViewCliniciansComponent)
          }
        ]
      },
      {
        path: 'ShiftPreferences',
        loadComponent: () => import('../app/modules/shift-preferences/shift-preferences.component').then(m => m.ShiftPreferencesComponent)
      },
      {
        path: 'Units/:facilityId',
        loadComponent: () => import('../app/modules/units/units.component').then(m => m.UnitsComponent)
      },
      {
        path: 'JobTitles/:locationId',
        loadComponent: () => import('../app/modules/custom-job-titles/custom-job-titles.component').then(m => m.CustomJobTitlesComponent)
      },
      {
        path: 'AvailableJobTitles/:locationId',
        loadComponent: () => import('../app/modules/assign-facility-jobs/assign-facility-jobs.component').then(m => m.AssignFacilityJobsComponent)
      },
      {
        path: 'Profile',
        loadComponent: () => import('../app/modules/profile/profile.component').then(m => m.ProfileComponent)
      },
      {
        path: 'Admin',
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: UserRoles.Administrators,
            redirectTo: 'Portal/Overview'
          }
        },
        children: [
          {
            path: 'Profile',
            loadComponent: () => import('../app/modules/profile/profile.component').then(m => m.ProfileComponent)
          },
          {
            path: 'Clients',
            children: [
              {
                path: '',
                loadComponent: () => import('../app/modules/admin/clients/clients.component').then(m => m.ClientsComponent)
              },
              {
                path: 'Edit/:userId',
                loadComponent: () => import('../app/modules/admin/clients/add-edit-clients/add-edit-clients.component').then(m => m.AddEditClientsComponent)
              },
              {
                path: 'Add',
                loadComponent: () => import('../app/modules/admin/clients/add-edit-clients/add-edit-clients.component').then(m => m.AddEditClientsComponent)
              }
            ]
          },
          {
            path: 'Clinicians',
            children: [
              {
                path: '',
                loadComponent: () => import('../app/modules/admin/clinicians/clinicians.component').then(m => m.CliniciansComponent)
              },
              {
                path: 'Edit/:userId',
                loadComponent: () => import('../app/modules/admin/clinicians/edit-clinicians/edit-clinicians.component').then(m => m.EditCliniciansComponent)
              }
            ]
          },
          {
            path: 'Administrators',
            children: [
              {
                path: '',
                loadComponent: () => import('../app/modules/admin/administrators/administrators.component').then(m => m.AdministratorsComponent)
              },
              {
                path: 'Edit/:userId',
                loadComponent: () => import('../app/modules/admin/administrators/add-edit-administrator/add-edit-administrator.component').then(m => m.AddEditAdministratorComponent)
              },
              {
                path: 'Add',
                loadComponent: () => import('../app/modules/admin/administrators/add-edit-administrator/add-edit-administrator.component').then(m => m.AddEditAdministratorComponent)
              }
            ]
          },
          {
            path: 'Locations',
            children: [
              {
                path: '',
                loadComponent: () => import('../app/modules/admin/locations/locations.component').then(m => m.AdminLocationsComponent)
              },
              {
                path: 'Add',
                loadComponent: () => import('../app/modules/admin/locations//add-location/add-location.component').then(m => m.AddLocationComponent)
              },
              {
                path: 'Edit/:locationId',
                loadComponent: () => import('../app/modules/admin/locations/edit-location/edit-location.component').then(m => m.EditLocationComponent)
              }
            ]
          },
          {
            path: 'Networks',
            children: [
              {
                path: '',
                loadComponent: () => import('../app/modules/admin/networks/networks.component').then(m => m.NetworksComponent)
              },
              {
                path: 'Edit/:employerId',
                loadComponent: () => import('../app/modules/admin/networks/add-edit-network/add-edit-network.component').then(m => m.AddEditNetworkComponent)
              },
              {
                path: 'Add',
                loadComponent: () => import('../app/modules/admin/networks/add-edit-network/add-edit-network.component').then(m => m.AddEditNetworkComponent)
              }
            ]
          },
          {
            path: 'Rates',
            children: [
              {
                path: '',
                loadComponent: () => import('../app/modules/admin/rates/rates.component').then(m => m.RatesComponent)
              },
              {
                path: 'Edit/:rateId/:rateLevel',
                loadComponent: () => import('../app/modules/admin/rates/add-edit-rate/add-edit-rate.component').then(m => m.AddEditRateComponent)
              },
              {
                path: 'Add',
                loadComponent: () => import('../app/modules/admin/rates/add-edit-rate/add-edit-rate.component').then(m => m.AddEditRateComponent)
              }
            ]
          },
          {
            path: 'IntacctCodes',
            loadComponent: () => import('../app/modules/admin/intacct-codes/intacct-codes.component').then(m => m.IntacctCodesComponent)
          },
          {
            path: 'Holidays',
            children: [
              {
                path: '',
                loadComponent: () => import('../app/modules/admin/holidays/holidays.component').then(m => m.HolidaysComponent)
              },
              {
                path: 'Edit/:holidayId',
                loadComponent: () => import('../app/modules/admin/holidays/add-edit-holiday/add-edit-holiday.component').then(m => m.AddEditHolidayComponent)
              },
              {
                path: 'Add',
                loadComponent: () => import('../app/modules/admin/holidays/add-edit-holiday/add-edit-holiday.component').then(m => m.AddEditHolidayComponent)
              },
              {
                path: 'Edit-Common-Holiday/:holidayConfigDefaultId',
                loadComponent: () => import('../app/modules/admin/holidays/common-holidays/add-edit-common-holiday/add-edit-common-holiday.component').then(m => m.AddEditCommonHolidayComponent)
              },
              {
                path: 'Add-Common-Holiday',
                loadComponent: () => import('../app/modules/admin/holidays/common-holidays/add-edit-common-holiday/add-edit-common-holiday.component').then(m => m.AddEditCommonHolidayComponent)
              },
              {
                path: 'Edit-common-configuration-level-holiday/:isFacility/:commonConfigLevelId',
                loadComponent: () => import('../app/modules/admin/holidays/common-configuration-level/add-edit-common-configuration-level/add-edit-common-configuration-level.component').then(m => m.AddEditCommonConfigurationLevelComponent)
              },
              {
                path: 'Add-common-configuration-level-holiday/:isFacility',
                loadComponent: () => import('../app/modules/admin/holidays/common-configuration-level/add-edit-common-configuration-level/add-edit-common-configuration-level.component').then(m => m.AddEditCommonConfigurationLevelComponent)
              }
            ]
          },
          {
            path: 'Timesheets',
            children: [
              {
                path: '',
                loadComponent: () => import('../app/modules/admin/timesheets/timesheets.component').then(m => m.TimesheetsComponent)
              },
              {
                path: 'Edit/:rateId',
                loadComponent: () => import('../app/modules/admin/timesheets/add-edit-timesheet/add-edit-timesheet.component').then(m => m.AddEditTimesheetComponent)
              },
              {
                path: 'Add',
                loadComponent: () => import('../app/modules/admin/timesheets/add-edit-timesheet/add-edit-timesheet.component').then(m => m.AddEditTimesheetComponent)
              }
            ]
          },
          {
            path: 'SalesPlan',
            children: [
              {
                path: '',
                loadComponent: () => import('../app/modules/admin/sales-plan/sales-plan.component').then(m => m.SalesPlanComponent)
              }
            ]
          },
          {
            path: 'Dashboard',
            children: [
              {
                path: 'Global-Dashboard',
                loadComponent: () => import('../app/modules/admin/dashboard/dashboard.component').then(m => m.DashboardComponent)
              },
              {
                path: 'My-Dashboard',
                loadComponent: () => import('../app/modules/admin/dashboard/dashboard.component').then(m => m.DashboardComponent)
              },
              {
                path: 'FinanceReportHistory',
                loadComponent: () => import('../app/modules/admin/dashboard/finance-report-history/finance-report-history.component').then(m => m.FinanceReportHistoryComponent)
              }
            ]
          },
          {
            path: 'Markets',
            children: [
              {
                path: '',
                loadComponent: () => import('../app/modules/admin/markets/markets.component').then(m => m.MarketsComponent)
              },
              {
                path: 'Edit/:marketId',
                loadComponent: () => import('../app/modules/admin/markets/add-edit-market/add-edit-market.component').then(m => m.AddEditMarketComponent)
              },
              {
                path: 'Add',
                loadComponent: () => import('../app/modules/admin/markets/add-edit-market/add-edit-market.component').then(m => m.AddEditMarketComponent)
              }
            ]
          },
          {
            path: 'CostCenters',
            children: [
              {
                path: '',
                loadComponent: () => import('../app/modules/admin/costcenters/costcenters.component').then(m => m.CostCentersComponent)
              },
              {
                path: 'Add',
                loadComponent: () => import('../app/modules/admin/costcenters/add-edit-costcenter/add-edit-costcenter.component').then(m => m.AddEditCostCenterComponent)
              }
            ]
          },
          {
            path: 'ShiftPreferences',
            loadComponent: () => import('../app/modules/admin/shift-preferences/shift-preferences.component').then(m => m.ShiftPreferencesComponent)
          },
          {
            path: 'Units/:facilityId',
            loadComponent: () => import('../app/modules/admin/units/units.component').then(m => m.UnitsComponent)
          },
          {
            path: 'JobTitles/:locationId',
            loadComponent: () => import('../app/modules/admin/custom-job-titles/custom-job-titles.component').then(m => m.CustomJobTitlesComponent)
          },
          {
            path: 'AvailableJobTitles/:locationId',
            loadComponent: () => import('../app/modules/admin/assign-facility-jobs/assign-facility-jobs.component').then(m => m.AssignFacilityJobsComponent)
          },
          {
            path: 'OvertimeRules',
            children: [
              {
                path: '',
                loadComponent: () => import('../app/modules/admin/overtime-rules/overtime-rules.component').then(m => m.OvertimeRulesComponent)
              },
              {
                path: 'DefaultOTEdit/:id',
                loadComponent: () => import('../app/modules/admin/overtime-rules/add-edit-overtime-rule/add-edit-overtime-rules.component').then(m => m.AddEditOvertimeRulesComponent)
              },
              {
                path: 'OTEdit/:id',
                loadComponent: () => import('../app/modules/admin/overtime-rules/add-edit-overtime-rule/add-edit-overtime-rules.component').then(m => m.AddEditOvertimeRulesComponent)
              },
              {
                path: 'Add',
                loadComponent: () => import('../app/modules/admin/overtime-rules/add-edit-overtime-rule/add-edit-overtime-rules.component').then(m => m.AddEditOvertimeRulesComponent)
              }
            ]
          },
          {
            path: 'PendingCredentials',
            loadComponent: () => import('../app/modules/admin/pending-credentials/pending-credentials.component').then(m => m.PendingCredentailsComponent)
          },
          {
            path: 'ClinicianRequest',
            loadComponent: () => import('../app/modules/admin/clinician-request/clinician-request.component').then(m => m.ClinicianRequestComponent)
          },
          {
            path: 'NetworkHours',
            children: [
              {
                path: '',
                loadComponent: () => import('../app/modules/admin/network-hours-and-weekend-config/network-hours-and-weekend-config.component').then(m => m.NetworkHoursAndWeekendConfigComponent)
              },
              {
                path: 'AddShiftBaseHour/:type',
                loadComponent: () => import('../app/modules/admin/network-hours-and-weekend-config/network-hours/add-edit-shift-base-hours/add-edit-shift-base-hours.component').then(m => m.AddEditShiftBaseHoursComponent)
              },
              {
                path: 'EditShiftBaseHour/:type/:id',
                loadComponent: () => import('../app/modules/admin/network-hours-and-weekend-config/network-hours/add-edit-shift-base-hours/add-edit-shift-base-hours.component').then(m => m.AddEditShiftBaseHoursComponent)
              },
              {
                path: 'AddWeekendConfig/:type',
                loadComponent: () => import('../app/modules/admin/network-hours-and-weekend-config/weekend-configuration/add-edit-weekend-type/add-edit-weekend-type.component').then(m => m.AddEditWeekendTypeComponent)
              },
              {
                path: 'EditWeekendConfig/:type/:id',
                loadComponent: () => import('../app/modules/admin/network-hours-and-weekend-config/weekend-configuration/add-edit-weekend-type/add-edit-weekend-type.component').then(m => m.AddEditWeekendTypeComponent)
              }
            ]
          },
          {
            path: 'Questionnaires',
            loadComponent: () => import('../app/modules/admin/questionnaire/questionnaire.component').then(m => m.QuestionnaireComponent)
          },
          {
            path: 'master-data-hub',
            loadComponent: () => import('./modules/admin/master-data-hub/master-common-data/master-common-data.component').then(m => m.MasterCommonDataComponent)
          },
          {
            path: 'health-care-skills',
            loadComponent: () => import('./modules/admin/master-data-hub/master-health-container/master-health-container.component').then(m => m.MasterHealthContainerComponent),
            children: [
              {
                path: '',
                loadComponent: () => import('./modules/admin/master-data-hub/master-health-container/master-health-care/master-health-care.component').then(m => m.MasterHealthCareComponent)
              },
              {
                path: 'save',
                loadComponent: () => import('./modules/admin/master-data-hub/health-care-system/health-care-system.component').then(m => m.HealthCareSystemComponent)
              }
            ]
          },
          {
            path: 'Applicants',
            loadComponent: () => import('../app/shared/components/clinician-request/job-applicant-details/job-applicant-details.component').then(m => m.JobApplicantDetailsComponent)
          }
        ]
      },
      {
        path: 'ClinicianRequest',
        loadComponent: () => import('../app/modules/clinician-request/clinician-request.component').then(m => m.ClinicianRequestComponent)
      },
      {
        path: '**',
        redirectTo: 'Overview'
      }
    ]
  },
  {
    path: 'job-leads',
    loadComponent: () => import('../app/modules/job-leads/job-leads.component').then(m => m.JobLeadsComponent)
  },
  { path: '**', redirectTo: '/Login' }
];
