// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { LocalStorageService } from 'ngx-webstorage';
import { LocalStorageKey } from '../../shared/models/enums/local-storage-key.enum';
import { UserRoles } from '../../shared/models/user.model';
import { UserActionsService } from '../../store/actions';
import { RefreshToken, AccessToken } from '@sadiant/data-access';

interface Jwt extends JwtPayload {
  role?: UserRoles | UserRoles[];
  unique_name: string;
  delegate_access_user_email?: string;
  delegate_access_user_id?: string;
  roles?: string | '';
}

@Injectable({ providedIn: 'root' })
export class TokenService {
  constructor(private userActionsService: UserActionsService, private localStorageService: LocalStorageService) {}

  saveTokensToLocalStorage(tokens: { accessToken: AccessToken; refreshToken: RefreshToken }): boolean {
    try {
      this.userActionsService.updateUserRoles(this.getRolesFromJwt(tokens.accessToken?.jwt));
      if (tokens.accessToken) {
        this.localStorageService.store(LocalStorageKey.AccessToken, tokens.accessToken);
      }
      if (tokens.refreshToken) {
        this.localStorageService.store(LocalStorageKey.RefreshToken, tokens.refreshToken);
      }
      return true;
    } catch (e) {
      return false;
    }
  }

  deleteTokensFromLocalStorage(): void {
    this.userActionsService.updateUserRoles([]);
    this.localStorageService.clear(LocalStorageKey.AccessToken);
    this.localStorageService.clear(LocalStorageKey.RefreshToken);
  }

  getAccessTokenFromLocalStorage(): AccessToken | null {
    return this.getTokenFromLocalStorage<AccessToken>(LocalStorageKey.AccessToken);
  }

  getRefreshTokenFromLocalStorage(): RefreshToken | null {
    return this.getTokenFromLocalStorage<RefreshToken>(LocalStorageKey.RefreshToken);
  }

  private getTokenFromLocalStorage<T extends AccessToken | RefreshToken>(localStorageKey: LocalStorageKey): T | null {
    return (this.localStorageService.retrieve(localStorageKey) as T) || null;
  }

  getRolesFromJwtInLocalStorage(): UserRoles[] {
    const accessToken = this.getAccessTokenFromLocalStorage();
    return this.getRolesFromJwt(accessToken?.jwt);
  }
  getClaimsFromJwtInLocalStorage(): string[] {
    const accessToken = this.getAccessTokenFromLocalStorage();
    return this.getUserRolesFromJwt(accessToken?.jwt);
  }

  getUserId(): string {
    const accessToken = this.getTokenFromLocalStorage<AccessToken>(LocalStorageKey.AccessToken);
    if (!accessToken || !accessToken.jwt) {
      return '';
    }
    try {
      return jwt_decode<Jwt>(accessToken.jwt).sub ?? '';
    } catch (e) {
      return '';
    }
  }

  getUserEmail(): string {
    const accessToken = this.getTokenFromLocalStorage<AccessToken>(LocalStorageKey.AccessToken);
    if (!accessToken || !accessToken.jwt) {
      return '';
    }
    try {
      return jwt_decode<Jwt>(accessToken.jwt).unique_name ?? '';
    } catch (e) {
      return '';
    }
  }

  private getRolesFromJwt(jwt?: string): UserRoles[] {
    if (!jwt) {
      return [];
    }
    try {
      const accessTokenObject = jwt_decode<Jwt>(jwt);
      if (!accessTokenObject.role) {
        return [];
      }
      return typeof accessTokenObject.role === 'string' ? [accessTokenObject.role] : [...accessTokenObject.role];
    } catch (e) {
      return [];
    }
  }
  private getUserRolesFromJwt(jwt?: string): string[] {
    if (!jwt) {
      return [];
    }
    try {
      const accessTokenObject = jwt_decode<Jwt>(jwt);
      if (!accessTokenObject.roles) {
        return [];
      }
      return accessTokenObject.roles ? accessTokenObject.roles?.split(',') : [];
    } catch (e) {
      return [];
    }
  }
  getDecodedToken(): { delegate_access_user_email: string; delegate_access_user_id: string } | null {
    let decodedJwt: { delegate_access_user_email: string; delegate_access_user_id: string } | null = null;
    const accessToken: AccessToken | null = this.getAccessTokenFromLocalStorage();
    if (accessToken && accessToken.jwt) {
      const tokenString: string = accessToken.jwt;
      const decodedVal = jwt_decode<Jwt>(tokenString);
      decodedJwt = {
        delegate_access_user_email: decodedVal.delegate_access_user_email ?? '',
        delegate_access_user_id: decodedVal.delegate_access_user_id ?? ''
      };
    }
    return decodedJwt;
  }
}
