// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Action, createReducer, on } from '@ngrx/store';
import { tableFilterActions } from '../actions';
import { Store_TableFilter } from '../actions/table-filter.model';

const initialState: Store_TableFilter = {
  filters: []
} as const;

const _tableFiltersReducer = createReducer(
  initialState,

  on(tableFilterActions.setFilter, (state, { table, value }) => {
    const filters = state.filters.filter(f => f.table !== table);
    filters.push({ table, value });
    return { ...state, filters: JSON.parse(JSON.stringify(filters)) };
  }),

  on(tableFilterActions.clearFilter, (state, { table }) => {
    const filters = state.filters.filter(f => f.table !== table);
    return { ...state, filters: JSON.parse(JSON.stringify(filters)) };
  }),

  on(tableFilterActions.clearAllFilters, (state, {}) => {
    return { ...state, filters: [] };
  })
);

export function tableFiltersReducer(state: Store_TableFilter | undefined, action: Action): Store_TableFilter {
  return _tableFiltersReducer(state, action);
}
