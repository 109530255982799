// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Action, createReducer, on } from '@ngrx/store';
import { facilityActions } from '../actions';
import { Store_Facility } from '../actions/facility.model';

const initialState: Store_Facility = {
  employerId: null,
  name: null,
  companyName: null,
  description: null
} as const;

const _facilityReducer = createReducer(
  initialState,

  on(facilityActions.setFacility, (state, { employerId, name, companyName, description }) => {
    return { ...state, employerId, name, companyName, description };
  }),

  on(facilityActions.clearFacility, (state, {}) => {
    return { ...state, employerId: null, name: null, companyName: null, description: null };
  })
);

export function facilityReducer(state: Store_Facility | undefined, action: Action): Store_Facility {
  return _facilityReducer(state, action);
}
