<!--Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.-->
<p-toast [preventOpenDuplicates]="true"
         [autoZIndex]="false"
         [styleClass]="'z-index-999999'">
  <ng-template let-message
               pTemplate="message">
    <div class="toast-icon-wrapper">
      <i class="pi {{message.icon}}"></i>
      <div class="text-left">
        <h4>{{message.summary}}</h4>
        <p>{{message.detail}}</p>
      </div>
    </div>
  </ng-template>
</p-toast>
