// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { Store_OverviewLocation } from './overview-location.model';

export const setOverviewLocation = createAction('[OVERVIEW_LOCATION] Set', props<{ locationId: number | null; first: number }>());
export const clearOverviewLocation = createAction('[OVERVIEW_LOCATION] Clear');

@Injectable({ providedIn: 'root' })
export class OverviewLocationActionsService {
  constructor(private store: Store<AppState>) {}

  setOverviewLocation = (overviewLocation: Store_OverviewLocation): void =>
    this.store.dispatch(
      setOverviewLocation({
        locationId: overviewLocation.locationId,
        first: overviewLocation.first
      })
    );

  clearOverviewLocation = (): void => this.store.dispatch(clearOverviewLocation());
}
