// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { State } from '@ngrx/store';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { AppState } from '../../app.state';

@Injectable()
export class EmployerIdInterceptor implements HttpInterceptor {
  constructor(private permissionService: NgxPermissionsService, private state: State<AppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const appState = this.state.getValue() as AppState;
    const isAdmin = this.permissionService.getPermission('Administrators');
    if (isAdmin && appState && appState.facility && appState.facility.employerId) {
      request = request.clone({ setHeaders: { employerid: appState.facility.employerId.toString() } });
    }
    return next.handle(request);
  }
}
