// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Action, createReducer, on } from "@ngrx/store";
import { ClinicianRequestCountAction } from "../actions";
import { Store_ClinicianRequestCount } from "../actions/clinicianRequestBadge.model";

const initialState: Store_ClinicianRequestCount = {
  actionNeededCount: 0,
  pendingRequestCount: 0
} as const;

const _clinicianRequestCountReducer = createReducer(
  initialState,
  on(ClinicianRequestCountAction.updateClinicianRequestCount, (state, { actionNeededCount, pendingRequestCount }) => {
    return { ...state, actionNeededCount, pendingRequestCount }
  })
)

export function clinicianRequestCountReducer(state: Store_ClinicianRequestCount | undefined, action: Action): Store_ClinicianRequestCount {
  return _clinicianRequestCountReducer(state, action);
}
