// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { EnvConfig } from '../models/env-config.model';

@Injectable({ providedIn: 'root' })
export class IntercomService {
  constructor(private router: Router, private envConfig: EnvConfig) {}

  trackPageChanges() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.update({ last_request_at: parseInt((new Date().getTime() / 1000).toString(), 10) });
    });
  }

  /**
   * If you'd like to control when Intercom is loaded, you can use the 'boot' method.
   * This is useful in situations like a one-page Javascript based application where the user may not be logged in
   * when the page loads. You call this method with the standard intercomSettings object.
   */
  boot(intercomData: any): void {
    const data = {
      ...intercomData,
      app_id: this.envConfig.intercomAppId
    };

    return this._callIntercom('boot', data);
  }

  /**
   * If you have the Respond product (combined with another product like Engage)
   * you should call the Intercom shutdown method to clear your users’ conversations anytime they logout
   * of your application. Otherwise, the cookie we use to track who was most recently logged in on a given device
   * or computer will keep these conversations in the Messenger for one week.
   * This method will effectively clear out any user data that you have been passing through the JS API.
   */
  shutdown(): void {
    return this._callIntercom('shutdown');
  }

  /**
   * Calling the update method without any other arguments will trigger the JavaScript to look for new messages
   * that should be displayed to the current user (the one whose details are in the window.intercomSettings variable)
   * and show them if they exist.
   *
   * Calling the update method with a JSON object of user details will update those fields on the current user
   * in addition to logging an impression at the current URL and looking for new messages for the user.
   */
  update(data?: any): void {
    return this._callIntercom('update', data);
  }

  /**
   * This will hide the main Messenger panel if it is open. It will not hide the Messenger Launcher.
   */
  hide(): void {
    return this._callIntercom('hide');
  }

  /**
   * This will show the Messenger. If there are no conversations it will open with the new message view,
   * if there are it will open with the message list.
   *
   * If a `message` parameter is supplied, it will automatically open a new message window, aliasing showNewMessage().
   *
   */
  show(message?: string): void {
    if (message) {
      return this.showNewMessage(message);
    }
    return this._callIntercom('show');
  }

  /**
   * To open the message window with the message list you can call `showMessages()`.
   */
  showMessages(): void {
    return this._callIntercom('showMessages');
  }

  /**
   * To open the message window with the new message view you can call showNewMessage().
   *
   * This function takes an optional parameter that can be used to pre-populate the message composer as shown below.
   */
  showNewMessage(message?: string): void {
    return this._callIntercom('showNewMessage', message);
  }

  /**
   * You can submit an event using the trackEvent method.
   * This will associate the event with the currently logged in user and send it to Intercom.
   * The final parameter is a map that can be used to send optional metadata about the event.
   *
   * You can also add custom information to events in the form of event metadata.
   */
  trackEvent(eventName: string, metadata?: any): void {
    return this._callIntercom('trackEvent', eventName, metadata);
  }

  /**
   * A visitor is someone who goes to your site but does not use the messenger.
   * You can track these visitors via the visitor user_id.
   * This user_id can be used to retrieve the visitor or lead through the REST API.
   */
  getVisitorId(): string {
    return this._callIntercom('getVisitorId');
  }

  /**
   * Alias for getVisitorId()
   * @alias getVisitorId()
   */
  get visitorId(): string {
    return this._callIntercom('getVisitorId');
  }

  /**
   * Gives you the ability to hook into the show event. Requires a function argument.
   */
  onShow(handler: () => void): void {
    return this._callIntercom('onShow', handler);
  }

  /**
   * Gives you the ability to hook into the hide event. Requires a function argument.
   */
  onHide(handler: () => void): void {
    return this._callIntercom('onHide', handler);
  }

  /**
   * This method allows you to register a function that will be called when the current number of unread messages changes.
   */
  onUnreadCountChange(handler: (unreadCount?: number) => void): void {
    return this._callIntercom('onUnreadCountChange', handler);
  }

  /**
   * If you would like to trigger a tour based on an action a user or visitor takes in your site or application,
   * ou can use this API method. You need to call this method with the id of the tour you wish to show. The id of
   * the tour can be found in the “Use tour everywhere” section of the tour editor.
   *
   * Please note that tours shown via this API must be published and the “Use tour everywhere” section must be
   * turned on. If you're calling this API using an invalid tour id, nothing will happen.
   */
  startTour(tourId: number): void {
    return this._callIntercom('startTour', tourId);
  }

  /**
   * Private handler to run Intercom methods safely
   */
  private _callIntercom(fn: string, ...args: any) {
    if ((window as any).Intercom) {
      return (window as any).Intercom(fn, ...args);
    }
    return;
  }
}
