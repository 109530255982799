// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { AppState } from '../../app.state';

export const updatePendingCredentialsCount = createAction(
  '[PendingCredentials] Update PendingCredentials',
  props<{ userCount: number; totalCount: number }>()
);

@Injectable({ providedIn: 'root' })
export class PendingCredentialCountActionsService {
  constructor(private store: Store<AppState>) {}

  updatePendingCredentialsCount = (userCount: number, totalCount: number): void =>
    this.store.dispatch(updatePendingCredentialsCount({ userCount, totalCount }));
}
