// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { Store_Facility } from './facility.model';

export const setFacility = createAction(
  '[FACILITY] Set',
  props<{
    employerId: number | null;
    name: string | null;
    companyName: string | null;
    description: string | null;
  }>()
);

export const clearFacility = createAction('[FACILITY] Clear');

@Injectable({ providedIn: 'root' })
export class FacilityActionsService {
  constructor(private store: Store<AppState>) {}

  setFacility = (facility: Store_Facility): void =>
    this.store.dispatch(
      setFacility({
        employerId: facility.employerId,
        name: facility.name,
        companyName: facility.companyName,
        description: facility.description
      })
    );

  clearFacility = (): void => this.store.dispatch(clearFacility());
}
