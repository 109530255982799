// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
export function overrideConfig(envConfig: any): any {
  for (const [key, _value] of Object.entries(envConfig)) {
    for (const k of Object.keys(localStorage)) {
      if (key === k) {
        if (typeof envConfig[key] === 'boolean') {
          if (localStorage[k].toLowerCase() === 'true') {
            const originalValue = envConfig[key];
            envConfig[key] = true;
            const newValue = envConfig[key];
            consoleOverridenValue(key, originalValue, newValue);
          } else if (localStorage[k].toLowerCase() === 'false') {
            const originalValue = envConfig[key];
            envConfig[key] = false;
            const newValue = envConfig[key];
            consoleOverridenValue(key, originalValue, newValue);
          }
        } else if (typeof envConfig[key] === 'string') {
          const originalValue = envConfig[key];
          envConfig[key] = localStorage[k];
          const newValue = envConfig[key];
          consoleOverridenValue(key, originalValue, newValue);
        }
      }
    }
  }
  return envConfig;
}
function consoleOverridenValue(key: string, originalValue: any, newValue: any) {
  var result = { 'OVERRIDEN KEY/VALUE': { key: `${key}`, originalValue: originalValue, newValue: newValue } };
  console.error('OVERRIDEN KEY/VALUE FROM CONFIG', `${key}`);
  console.table(result);
}
