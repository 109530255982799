// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppInsightsService } from '@sadiant/azure-app-insights';
import { Account } from '@sadiant/data-access';
import { Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AccountCoreApiService } from '../../shared/services/core-api/account-core-api.service';
import { IntercomService } from './../../shared/services/intercom.service';
import { ProfileService } from './../../shared/services/profile.service';
import { TableFilterActionsService, UserActionsService } from './../../store/actions';
import { FacilityActionsService } from './../../store/actions/facility.actions';
import { OverviewLocationActionsService } from './../../store/actions/overview-location.actions';
import { TokenService } from './token.service';

import RefreshTokenIn = Account.RefreshToken.RefreshTokenIn;
import RefreshTokenOut = Account.RefreshToken.RefreshTokenOut;
import LoginIn = Account.Login.LoginIn;

@Injectable({ providedIn: 'root' })
export class AuthenticationCoreService {
  constructor(
    private accountCoreApiService: AccountCoreApiService,
    private tokenService: TokenService,
    private profileService: ProfileService,
    private router: Router,
    private facilityActionsService: FacilityActionsService,
    private tableFilterActionService: TableFilterActionsService,
    private overviewLocationActionsService: OverviewLocationActionsService,
    private userActionsService: UserActionsService,
    private appInsights: AppInsightsService,
    private intercom: IntercomService
  ) {}

  login(request: LoginIn): Observable<void> {
    return this.accountCoreApiService.login(request).pipe(
      tap(loginResponse => this.tokenService.saveTokensToLocalStorage(loginResponse)),
      map(_ => {})
    );
  }

  getNewRefreshToken(): Observable<RefreshTokenOut> {
    const refreshToken = this.tokenService.getRefreshTokenFromLocalStorage();
    const request: RefreshTokenIn = {
      tokenValue: refreshToken?.token ?? '',
      tokenId: refreshToken?.tokenId ?? 0
    };
    if (!request.tokenValue || !request.tokenId) {
      return throwError('Invalid Token Value');
    } else {
      return this.accountCoreApiService
        .getRefreshToken(request)
        .pipe(tap(response => this.tokenService.saveTokensToLocalStorage(response)));
    }
  }

  logout(isRedirectWithOutError: boolean = true): void {
    const refreshToken = this.tokenService.getRefreshTokenFromLocalStorage();
    const request: RefreshTokenIn = {
      tokenValue: refreshToken?.token ?? '',
      tokenId: refreshToken?.tokenId ?? 0
    };
    if (request.tokenId && request.tokenValue) {
      this.accountCoreApiService.logOutRevokeToken(request).subscribe();
    }

    this.tokenService.deleteTokensFromLocalStorage();
    this.profileService.setProfile(null);
    this.clearAllCachedInfoFromStateStore();
    this.appInsights.clearUserId();
    this.intercom.shutdown();
    if (isRedirectWithOutError) {
      this.router.navigate(['/Login']);
    }
  }

  private clearAllCachedInfoFromStateStore(): void {
    this.facilityActionsService.clearFacility();
    this.overviewLocationActionsService.clearOverviewLocation();
    this.userActionsService.clearUser();
    this.tableFilterActionService.clearAllFilters();
  }
}
