// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { createAction, Store } from '@ngrx/store';
import { isHTMLElement } from '@sadiant/type-guards';
import { AppState } from '../../app.state';

export const startFetchingData = createAction('[FETCHING_DATA] Start');
export const stopFetchingData = createAction('[FETCHING_DATA] Stop');

@Injectable({ providedIn: 'root' })
export class FetchingDataActionsService {
  constructor(private store: Store<AppState>) {}

  startFetchingData = (): void => {
    var element = document?.activeElement;
    if (isHTMLElement(element)) {
      element.blur();
    }
    this.store.dispatch(startFetchingData());
  };

  stopFetchingData = (): void => this.store.dispatch(stopFetchingData());
}
