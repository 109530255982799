// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
export class EnvConfig {
  constructor(ec: EnvConfig) {
    this.production = ec.production;
    this.isPRODorSTG = ec.isPRODorSTG;
    /** BackEnd Urls */
    this.apiCorePathUrl = ec.apiCorePathUrl;
    this.signalRPathUrl = ec.signalRPathUrl;
    this.backendBaseUrl = ec.backendBaseUrl;
    /** FrontEnd Urls */
    this.clinicianPortalUrl = ec.clinicianPortalUrl;
    /** Keys */
    this.intercomAppId = ec.intercomAppId;
    this.appInsightsConnectionString = ec.appInsightsConnectionString;
    this.adobePdfClientId = ec.adobePdfClientId;
    /** Launch Darkly */
    this.inboxEnabled = ec.inboxEnabled;
    this.questionnaireModuleVisibility = ec.questionnaireModuleVisibility;
    this.tasksAreDisplayed = ec.tasksAreDisplayed;
    this.clinicianRequestsVisibleForNetworks = ec.clinicianRequestsVisibleForNetworks;
    this.networkHoursVisibility = ec.networkHoursVisibility;
  }
  readonly production: boolean;
  readonly isPRODorSTG: boolean;
  /** BackEnd Urls */
  readonly backendBaseUrl: string;
  readonly apiCorePathUrl: string;
  readonly signalRPathUrl: string;
  get apiCoreBaseUrl(): string {
    return `${this.backendBaseUrl}/${this.apiCorePathUrl}`;
  }
  get signalRApieUrl(): string {
    return `${this.backendBaseUrl}/${this.signalRPathUrl}`;
  }
  /** FrontEnd Urls */
  readonly clinicianPortalUrl: string;
  /** Keys */
  readonly intercomAppId: string;
  readonly appInsightsConnectionString: string;
  readonly adobePdfClientId: string;
  /** Launch Darkly */
  readonly inboxEnabled: boolean;
  readonly tasksAreDisplayed: boolean;
  readonly clinicianRequestsVisibleForNetworks: boolean;
  readonly networkHoursVisibility: boolean;
  readonly questionnaireModuleVisibility: boolean;
}
