// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { CommonModule } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterModule
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AppInsightsService } from '@sadiant/azure-app-insights';
import { DialogHelperService } from '@sadiant/feature-dialog-helper-service';
import { ToastService } from '@sadiant/toast';
import { PrimeNGConfig } from 'primeng/api';
import { filter, tap } from 'rxjs/operators';
import { ToastComponent } from '../../../../libs/toast/src/lib/toast.component';
import { AppState } from './app.state';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { FetchingDataActionsService } from './store/actions/fetching-data.actions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [RouterModule, LoadingComponent, ToastComponent, CommonModule]
})
export class AppComponent implements OnInit, AfterViewChecked {
  fetchingData$ = this.store.select('fetchingData');
  facility$ = this.store.select('facility');
  overviewLocation$ = this.store.select('overviewLocation');
  user$ = this.store.select('user');

  constructor(
    private store: Store<AppState>,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private fetchingDataActionsService: FetchingDataActionsService,
    private primengConfig: PrimeNGConfig,
    private toastService: ToastService,
    private dialogHelperService: DialogHelperService,
    private appInsights: AppInsightsService
  ) {
    this.appInsights.clearUserId();
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.subscribeToRouteChanges();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  private subscribeToRouteChanges(): void {
    this.router.events
      .pipe(
        filter(
          (e): e is RouteConfigLoadStart | RouteConfigLoadEnd | NavigationError | NavigationCancel | NavigationStart =>
            e instanceof RouteConfigLoadStart ||
            e instanceof RouteConfigLoadEnd ||
            e instanceof NavigationError ||
            e instanceof NavigationCancel ||
            e instanceof NavigationStart
        ),
        tap(e =>
          /** Lazy loaded modules routing */
          e instanceof RouteConfigLoadStart
            ? this.fetchingDataActionsService.startFetchingData()
            : this.fetchingDataActionsService.stopFetchingData()
        ),
        tap(e =>
          /** Handle dialog opened during browser arrow navigation */
          e instanceof NavigationStart && e.navigationTrigger === 'popstate'
            ? this.dialogHelperService.closeWhenBrowserArroyNavigation(e)
            : undefined
        ),
        filter((e): e is NavigationError => e instanceof NavigationError),
        tap(e => this.toastService.showToastErrorMessage(`Error Navigating to ${e.url}`))
      )
      .subscribe();
  }
}
