// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Option } from '@sadiant/models';
export interface User {
  email: string;
  firstname: string;
  lastname: string;
}

export interface UsersColumns {
  field: string;
  header: string;
  isFilterableAndSortable?: boolean;
  isHidden?: boolean;
  isFilterable?: boolean;
  isSortable?: boolean;
  showColumn?: boolean;
  isNumeric?: boolean;
  selectFilterOptions?: Option[];
  hasDynamicSelection?: boolean;
  filterType?: string;
}

export enum UserRoles {
  // TODO: Remove user roles that are not in use
  'Administrators' = 'Administrators',
  'Client Administrator' = 'Client Administrator',
  'Client Shift Requester' = 'Client Shift Requester',
  'Client Team Member' = 'Client Team Member',
  'Clinician' = 'Clinician',
  'Editors' = 'Editors',
  'Employers' = 'Employers',
  'List Managers' = 'List Managers',
  'Public' = 'Public',
  'Users' = 'Users'
}
