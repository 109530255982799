// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Action, createReducer, on } from '@ngrx/store';
import { overviewLocationActions } from '../actions';
import { Store_OverviewLocation } from '../actions/overview-location.model';

const initialState: Store_OverviewLocation = {
  locationId: null,
  first: 0
} as const;

const _overviewLocationReducer = createReducer(
  initialState,

  on(overviewLocationActions.setOverviewLocation, (state, { locationId, first }) => {
    return { ...state, locationId, first };
  }),

  on(overviewLocationActions.clearOverviewLocation, (state, {}) => {
    return { ...state, locationId: null, first: 0 };
  })
);

export function overviewLocationReducer(state: Store_OverviewLocation | undefined, action: Action): Store_OverviewLocation {
  return _overviewLocationReducer(state, action);
}
