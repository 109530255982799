// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Account, Users } from '@sadiant/data-access';
import { LoadingBehavior } from '@sadiant/models';
import { Observable, of } from 'rxjs';
import { setLoading } from '../../components/loading/loading.interceptor';
import { EnvConfig } from '../../models/env-config.model';

import GetDefaultPasswordOut = Account.GetDefaultPassword.GetDefaultPasswordOut;
import AddUserByClientIn = Account.AddUserByClient.AddUserByClientIn;
import AddUserByClientOut = Account.AddUserByClient.AddUserByClientOut;
import ChangePasswordByClientIn = Account.ChangePasswordByClient.ChangePasswordByClientIn;
import ChangePasswordByClientOut = Account.ChangePasswordByClient.ChangePasswordByClientOut;
import ChangePasswordIn = Account.ChangePassword.ChangePasswordIn;
import ChangePasswordOut = Account.ChangePassword.ChangePasswordOut;
import CheckDelegateAccessIn = Account.CheckDelegateAccess.CheckDelegateAccessIn;
import CheckDelegateAccessOut = Account.CheckDelegateAccess.CheckDelegateAccessOut;
import EditUserByClientIn = Account.EditUserByClient.EditUserByClientIn;
import EditUserByClientOut = Account.EditUserByClient.EditUserByClientOut;
import RefreshTokenIn = Account.RefreshToken.RefreshTokenIn;
import RefreshTokenOut = Account.RefreshToken.RefreshTokenOut;
import LoginIn = Account.Login.LoginIn;
import LoginOut = Account.Login.LoginOut;
import RevokeTokenIn = Account.RevokeToken.RevokeTokenIn;
import RevokeTokenOut = Account.RevokeToken.RevokeTokenOut;
import RemoveUserIn = Users.RemoveUser.RemoveUserIn;
import RemoveUserResponseOut = Users.RemoveUser.RemoveUserResponseOut;
import ResetPasswordConfirmationIn = Account.ResetPasswordConfirmation.ResetPasswordConfirmationIn;
import ResetPasswordConfirmationOut = Account.ResetPasswordConfirmation.ResetPasswordConfirmationOut;
import ResetPasswordOut = Account.ResetPassword.ResetPasswordOut;

@Injectable({ providedIn: 'root' })
export class AccountCoreApiService {
  private readonly url = `${this.envConfig.apiCoreBaseUrl}/account`;

  constructor(private http: HttpClient, private envConfig: EnvConfig) {}

  login(request: LoginIn): Observable<LoginOut> {
    return this.http.post<LoginOut>(`${this.url}/login`, request);
  }

  getRefreshToken(request: RefreshTokenIn): Observable<RefreshTokenOut> {
    return this.http.post<RefreshTokenOut>(`${this.url}/refresh-token`, request);
  }

  logOutRevokeToken(request: RevokeTokenIn): Observable<RevokeTokenOut> {
    if (request && request.tokenId > 0 && request.tokenValue) {
      return this.http.post<RevokeTokenOut>(`${this.url}/revoke-token`, request);
    } else {
      return of({ errors: [], message: '' });
    }
  }

  getServerDate(loadingBehavior: LoadingBehavior = LoadingBehavior.Normal): Observable<string> {
    return this.http.get(`${this.url}/server-date`, { responseType: 'text', context: setLoading(loadingBehavior) });
  }

  resetPassword(email: string): Observable<ResetPasswordOut> {
    return this.http.post<ResetPasswordOut>(`${this.url}/reset-password`, { email });
  }

  removeUser(deleteUser: RemoveUserIn): Observable<RemoveUserResponseOut> {
    return this.http.post<RemoveUserResponseOut>(`${this.url}/removeuser`, deleteUser);
  }

  resetPasswordConfirmation(data: ResetPasswordConfirmationIn): Observable<ResetPasswordConfirmationOut> {
    return this.http.post<ResetPasswordConfirmationOut>(`${this.url}/reset-password-confirmation`, data);
  }

  changePassword(changePassword: ChangePasswordIn): Observable<ChangePasswordOut> {
    return this.http.post<ChangePasswordOut>(`${this.url}/change-password`, changePassword);
  }

  changePasswordByUserId(changePassword: ChangePasswordByClientIn, userId: string): Observable<ChangePasswordByClientOut> {
    return this.http.post<ChangePasswordByClientOut>(`${this.url}/change-password/${userId}`, changePassword);
  }

  addUser(user: AddUserByClientIn): Observable<AddUserByClientOut> {
    return this.http.post<AddUserByClientOut>(`${this.url}`, user);
  }

  editUser(user: EditUserByClientIn): Observable<EditUserByClientOut> {
    return this.http.put<EditUserByClientOut>(`${this.url}`, user);
  }

  getDefaultPassword(): Observable<GetDefaultPasswordOut> {
    return this.http.get<GetDefaultPasswordOut>(`${this.url}/default-password`);
  }

  checkDelegateAccess(request: CheckDelegateAccessIn): Observable<CheckDelegateAccessOut> {
    return this.http.post<CheckDelegateAccessOut>(`${this.url}/login/check-delegate-access`, request);
  }
}
