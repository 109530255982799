// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { IErrorService } from '@microsoft/applicationinsights-angularplugin-js';

export class GlobalErrorHandler implements IErrorService {
  handleError(error: any): void {
    try {
      console.error(error);
      const chunkFailedMessage = /(Loading chunk)(.*)(failed)/;
      if (chunkFailedMessage.test(error?.message) || chunkFailedMessage.test(`${error}`)) {
        window.location.reload();
      }
    } catch (e: any) {
      console.error(e);
    }
  }
}
