// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationCoreService } from './core/authentication/authentication-core.service';
import { TokenService } from './core/authentication/token.service';

@Injectable()
export class JWTGuard implements CanActivateChild {
  constructor(private tokenService: TokenService, private authenticationCoreService: AuthenticationCoreService) {}

  canActivateChild(): Observable<boolean> {
    const accessToken = this.tokenService.getAccessTokenFromLocalStorage();
    if (accessToken) {
      return of(true);
    } else {
      this.authenticationCoreService.logout();
      return of(false);
    }
  }
}
