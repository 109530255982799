// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { AppInsightsConfig } from './app-insights-config.model';
import { GlobalErrorHandler } from './error-handler';

@Injectable()
export class AppInsightsService implements IAppInsightsService {
  private angularPlugin = new AngularPlugin();
  private appInsights: ApplicationInsights;
  private envConfigAppInsights = inject(AppInsightsConfig);
  private router = inject(Router);

  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        connectionString: this.envConfigAppInsights.appInsightsConnectionString.toLowerCase(),
        disableInstrumentationKeyValidation: true,
        loggingLevelConsole: 2,
        loggingLevelTelemetry: 2,
        enableDebugExceptions: true,
        enableCorsCorrelation: true,
        /** https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-angular-plugin#route-tracking */
        enableAutoRouteTracking: false,
        extensions: [this.angularPlugin as any],
        extensionConfig: {
          [this.angularPlugin.identifier]: {
            router: this.router,
            errorServices: [new GlobalErrorHandler()]
          }
        }
      }
    });
    this.appInsights.loadAppInsights();
  }

  setUserId(userId: string): void {
    this.appInsights.setAuthenticatedUserContext(userId, userId, true);
  }

  clearUserId(): void {
    this.appInsights.clearAuthenticatedUserContext();
  }

  logPageView(name?: string, uri?: string): void {
    this.appInsights.trackPageView({ name, uri });
  }

  logEvent(name: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackEvent({ name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }): void {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  logTrace(message: string, properties?: { [key: string]: any }): void {
    this.appInsights.trackTrace({ message }, properties);
  }

  logException(exception?: IExceptionTelemetry): void {
    if (exception) {
      this.appInsights.trackException(exception);
    }
  }
}

@Injectable()
export class AppInsightsServiceForLocalDev implements IAppInsightsService {
  setUserId(_userId: string): void {}
  clearUserId(): void {}
  logPageView(_name?: string, _uri?: string): void {}
  logEvent(_name: string, _properties?: { [key: string]: any }) {}
  logMetric(_name: string, _average: number, _properties?: { [key: string]: any }) {}
  logTrace(_message: string, _properties?: { [key: string]: any }) {}
  logException(_exception?: IExceptionTelemetry): void {}
}

interface IAppInsightsService {
  setUserId(userId: string): void;
  clearUserId(): void;
  logPageView(name?: string, uri?: string): void;
  logEvent(name: string, properties?: { [key: string]: any }): void;
  logMetric(name: string, average: number, properties?: { [key: string]: any }): void;
  logTrace(message: string, properties?: { [key: string]: any }): void;
  logException(exception?: IExceptionTelemetry): void;
}
