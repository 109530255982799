// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { NavigationStart } from '@angular/router';
import { isHTMLElement } from '@sadiant/type-guards';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Injectable({ providedIn: 'root' })
export class DialogHelperService {
  private openDialogRef?: DynamicDialogRef;

  constructor() {}

  addOpenDialogRef(dialogRef: DynamicDialogRef) {
    this.closeExistingOpenedDialogIfAnyIsOpened();
    this.setNewDialogRef(dialogRef);
    this.blurButtonThatOpenedDialog();
  }

  closeWhenBrowserArroyNavigation(item: NavigationStart): void {
    if (item.navigationTrigger === 'popstate') {
      this.closeExistingOpenedDialogIfAnyIsOpened();
    }
  }

  private closeExistingOpenedDialogIfAnyIsOpened(): void {
    this.openDialogRef?.close();
  }

  private setNewDialogRef(dialogRef: DynamicDialogRef): void {
    if (dialogRef) {
      this.openDialogRef = dialogRef;
    }
  }

  private blurButtonThatOpenedDialog(): void {
    var element = document?.activeElement;
    if (isHTMLElement(element)) {
      element.blur();
    }
  }
}
