// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { defaultProfileImageBase64 } from '@sadiant/utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  NotificationSetting,
  Profile,
  TimesheetNotificationSettings,
  TaskNotificationSettings,
  ChatNotificationSettings
} from '../models/profile.model';

//TODO: Refactor this service with NgRx

@Injectable({ providedIn: 'root' })
export class ProfileService {
  private profile: Profile | null = null;
  private profileImage: string | null = null;
  private notificationSettings: NotificationSetting[] | null = null;
  private timesheetNotificationSettings: TimesheetNotificationSettings[] | null = null;
  private profileImageBehavior: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private taskNotificationSettings: TaskNotificationSettings[] | null = null;
  private chatNotificationSettings: ChatNotificationSettings[] | null = null;
  profileImageObservable: Observable<string | null>;

  isProfileChange$: Subject<boolean> = new Subject();

  constructor() {
    this.profileImageObservable = this.profileImageBehavior.asObservable();
  }

  getProfile(): Profile | null {
    return this.profile;
  }

  isProfileChanged$(): Observable<boolean> {
    return this.isProfileChange$.asObservable();
  }

  setProfile(profile: Profile | null): void {
    this.profile = profile;
    this.isProfileChange$.next(true);
  }

  getNotificationSettings(): NotificationSetting[] | null {
    return this.notificationSettings;
  }

  setNotificationSettings(notificationSettings: NotificationSetting[]): void {
    this.notificationSettings = notificationSettings;
  }

  getTimesheetNotificationSettings(): TimesheetNotificationSettings[] | null {
    return this.timesheetNotificationSettings;
  }

  setTimesheetNotificationSettings(timesheetNotificationSettings: TimesheetNotificationSettings[]): void {
    this.timesheetNotificationSettings = timesheetNotificationSettings;
  }

  getTaskNotificationSettings(): TaskNotificationSettings[] | null {
    return this.taskNotificationSettings;
  }

  setTaskNotificationSettings(taskNotificationSettings: TaskNotificationSettings[]): void {
    this.taskNotificationSettings = taskNotificationSettings;
  }

  getChatNotificationSettings(): ChatNotificationSettings[] | null {
    return this.chatNotificationSettings;
  }

  setChatNotificationSettings(chatNotificationSettings: ChatNotificationSettings[]): void {
    this.chatNotificationSettings = chatNotificationSettings;
  }

  getProfileImage(): string {
    return this.profileImage ? this.profileImage : `data:${defaultProfileImageBase64.mime};base64,${defaultProfileImageBase64.data}`;
  }

  setProfileImage(profileImage: string | null): void {
    this.profileImage = profileImage;
    this.profileImageBehavior.next(this.profileImage);
  }
}
