// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Duration, intervalToDuration, isAfter, isBefore, isEqual } from 'date-fns';
import { nameOf } from './name-of';

export function sadiantStandardDateFormat(): 'MM/dd/yyyy' {
  return 'MM/dd/yyyy';
}
export function sadiantStandardDateYearFormat(): 'yyyy-MM-dd' {
  return 'yyyy-MM-dd';
}

export function sadiantStandardTimeFormat(): 'HH:mm' {
  return 'HH:mm';
}

/** Converts 'yyyy-MM-ddTHH:mm:ss' to format */
export function stringDateTimeToFormat(dateTime: string, format: 'yyyy-MM-dd' | 'HH:mm'): string {
  const [date, time] = dateTime.split('T') as [string, string];

  if (format === 'yyyy-MM-dd') {
    return date;
  }

  const [hours, minutes] = time.split(':') as [string, string, string];
  return `${hours}:${minutes}`;
}

export function getDurationAsStringFromDates(start: Date, end: Date): { durationAsString: string; isEndBeforeStart: boolean } {
  const duration = intervalToDuration({ start, end });
  const isEndBeforeStart = isBefore(end, start);
  const durationAsString = formatDuration(duration);
  return { durationAsString, isEndBeforeStart };
}

export function getDurationAsStringFromSeconds(durationInSeconds: number): string {
  const duration = intervalToDuration({
    start: 0,
    end: durationInSeconds * 60 * 1000
  });
  return formatDuration(duration);
}

export function checkIfDateRangesOverlap(startA: Date, endA: Date, startB: Date, endB: Date, includeEdgesOverlap?: boolean): boolean {
  if (includeEdgesOverlap) {
    /** https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap */
    /** startA <= endB && endA >= startB */
    return (isBefore(startA, endB) || isEqual(startA, endB)) && (isAfter(endA, startB) || isEqual(endA, startB));
  } else {
    return isBefore(startA, endB) && isAfter(endA, startB);
  }
}

function formatDuration(d: Duration, includeSeconds?: boolean): string {
  return `
    ${d.years ? d.years + ` ${nameOf(d).years} ` : ''}
    ${d.months ? d.months + ` ${nameOf(d).months} ` : ''}
    ${d.weeks ? d.weeks + ` ${nameOf(d).weeks} ` : ''}
    ${d.days ? d.days + ` ${nameOf(d).days} ` : ''}
    ${d.hours ? d.hours + ` ${nameOf(d).hours} ` : ''}
    ${d.minutes ? d.minutes + ` ${nameOf(d).minutes} ` : ''}
    ${includeSeconds && d.seconds ? d.seconds + ` ${nameOf(d).seconds} ` : ''}
  `.trim();
}

export function newDateWithSetHoursZero(): Date {
  const newDate: Date = new Date();
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

export function getMonthList(enumObject: any): { value: any; label: string; icon: string }[] {
  const enumList = [];
  for (let key in enumObject) {
    if (!isNaN(Number(enumObject[key]))) {
      enumList.push({ id: enumObject[key], value: key });
    }
  }
  const list = enumList.map(n => ({ value: n, label: `${n.value}`, icon: '' }));
  return list;
}

export function getYearListFromCurrentYear() {
  const currentYear = new Date().getFullYear();
  const yearList = [];
  for (let year = 2024; year <= currentYear; year++) {
    yearList.push({ id: year, value: year });
  }
  const list = yearList.map(n => ({ value: n, label: `${n.value}`, icon: '' }));
  return list;
}
