// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Action, createReducer, on } from '@ngrx/store';
import { fetchingDataActions } from '../actions';
import { Store_FetchingData } from '../actions/fetching-data.model';

const initialState: Store_FetchingData = {
  isFetchingData: false,
  fetchingDataCount: 0
} as const;

const _fetchingDataReducer = createReducer(
  initialState,

  on(fetchingDataActions.startFetchingData, (state, {}) => {
    const fetchingDataCount = state.fetchingDataCount + 1;
    const isFetchingData = fetchingDataCount > 0;
    return { ...state, fetchingDataCount, isFetchingData };
  }),

  on(fetchingDataActions.stopFetchingData, (state, {}) => {
    const fetchingDataCount = Math.max(state.fetchingDataCount - 1, 0);
    const isFetchingData = fetchingDataCount > 0;
    return { ...state, fetchingDataCount, isFetchingData };
  })
);

export function fetchingDataReducer(state: Store_FetchingData | undefined, action: Action): Store_FetchingData {
  return _fetchingDataReducer(state, action);
}
