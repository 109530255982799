// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Action, createReducer, on } from '@ngrx/store';
import { pendingCredentialCountActions } from '../actions';
import { Store_PendingCredentialCount } from '../actions/pending-credential-count.model';

const initialState: Store_PendingCredentialCount = {
  userCount: 0,
  totalCount: 0
} as const;

const _pendingCredentialCountReducer = createReducer(
  initialState,
  on(pendingCredentialCountActions.updatePendingCredentialsCount, (state, { userCount, totalCount }) => {
    return { ...state, userCount, totalCount };
  })
);

export function pendingCredentialCountReducer(
  state: Store_PendingCredentialCount | undefined,
  action: Action
): Store_PendingCredentialCount {
  return _pendingCredentialCountReducer(state, action);
}
