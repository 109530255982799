// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from "@angular/core";
import { createAction, props, Store } from "@ngrx/store";
import { AppState } from "../../app.state";

export const updateClinicianRequestCount = createAction('[CLINICIAN_REQUEST] get request badge', props<{ actionNeededCount: number, pendingRequestCount: number }>());

@Injectable({ providedIn: 'root' })
export class ClinicianRequestCountActionService {

  constructor(private store: Store<AppState>) { }

  updateClinicianRequestCount = (actionNeededCount: number, pendingRequestCount: number): void => this.store.dispatch(updateClinicianRequestCount({ actionNeededCount, pendingRequestCount }));

}
