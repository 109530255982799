// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Injectable } from '@angular/core';
import { createAction, props, Store } from '@ngrx/store';
import { TSFixMe } from '@sadiant/models';
import { AppState } from '../../app.state';

export const setFilter = createAction(
  '[FILTER] Set',
  props<{
    table: string;
    value: TSFixMe | null;
  }>()
);

export const clearFilter = createAction('[TABLEFILTER] Clear', props<{ table: string }>());
export const clearAllFilters = createAction('[TABLEFILTER] ClearAll');

@Injectable({ providedIn: 'root' })
export class TableFilterActionsService {
  constructor(private store: Store<AppState>) {}

  setFilter = (table: string, value: TSFixMe): void => this.store.dispatch(setFilter({ table, value }));

  clearFilter = (table: string): void => this.store.dispatch(clearFilter({ table }));
  clearAllFilters = (): void => this.store.dispatch(clearAllFilters());
}
