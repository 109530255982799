// Copyright 2024 Sadiant Inc. All Rights Reserved. This software is subject to a license agreement. Unauthorized or unlicensed use is prohibited.
import { Action, createReducer, on } from '@ngrx/store';
import { UserRoles } from '../../shared/models/user.model';
import { userActions } from '../actions';
import { Store_User } from '../actions/user.model';

const initialState: Store_User = {
  email: '',
  firstName: '',
  lastName: '',
  roles: [],
  isSuperUser: false /** has 'Administrators' roles */
} as const;

const _userReducer = createReducer(
  initialState,

  on(userActions.updateUserEmail, (state, { email }) => {
    return { ...state, email };
  }),

  on(userActions.updateUserName, (state, { firstName, lastName }) => {
    return { ...state, firstName, lastName };
  }),

  on(userActions.updateUserRoles, (state, { roles }) => {
    const isSuperUser = roles.includes(UserRoles.Administrators);
    return { ...state, roles: [...roles], isSuperUser };
  }),

  on(userActions.clearUser, (state, {}) => {
    return { ...state, email: '', firstName: '', lastName: '', roles: [], isSuperUser: false };
  })
);

export function userReducer(state: Store_User | undefined, action: Action): Store_User {
  return _userReducer(state, action);
}
